import { Search } from "@mui/icons-material"
import { Autocomplete, InputAdornment, TextField } from "@mui/material"
import { equals } from "ramda"
import { fieldWidth } from "../../../styles/style-constants"


const SearchAutocompleteField = ({ label = "Search...", options = [], width, value, setValue, variant, disabled = false, getOptionLabel, renderOption, isMulti=false, groupBy = null, getOptionDisabled = null  }) => {
    return <Autocomplete
        sx={{
            width: width ?? fieldWidth
        }}
        options={options}
        disabled={disabled}
        value={value ?? (isMulti ? [] : null)}
        multiple={isMulti}
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        onChange={(event, newValue) => setValue(newValue)}
        isOptionEqualToValue={(a, b) => equals(a?.id)(b?.id)}
        disableCloseOnSelect={isMulti}
        groupBy={groupBy}
        getOptionDisabled={getOptionDisabled}
        
        renderInput={(params) =>
            <TextField label={label}
                variant={variant}
                InputProps={
                    { startAdornment: <InputAdornment position="end"><Search /></InputAdornment> }
                } {...params} />
        }

    />

}

export default SearchAutocompleteField