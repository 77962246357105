import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import CardContainer from "../../components/card-container";
import { Stack, Typography, TextField } from "@mui/material";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useSelector } from "react-redux";
import { selectCurrentClubId } from "../../redux/selectors";
import { useEffect } from "react";
import { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import {
  apiPathClubTeamsList,
  apiPathGetClub,
  apiPathGetEventTypes,
  apiPathGetOpponentList,
} from "../../utils/endpoint-paths";
import { Select, MenuItem } from "@mui/material";
import { map } from "ramda";
import GoogleMapsField from "../../components/fields/google-maps-field";
import {
  isNotNullOrUndefinedOrEmpty,
  isNullOrUndefinedOrEmpty,
} from "../../utils/helper-functions/is-null-or-undefined-or-empty";
import ButtonComponent from "../../components/button-component";
import { darkBlueButtonColor } from "../../styles/style-constants";
import {
  MAX_DESC_LENGTH,
  MAX_EVENT_NAME_LENGTH,
  invalidCell,
} from "../../utils/constants";

const ReviewPage = ({
  setFileContent,
  setPage,
  setHeaders,
  setFileName,
  handleBulkUpload,
  isSnackbarLoading,
  rows,
  setRows,
  doGet,
  generateEndpoint,
}) => {
  const currentClubId = useSelector(selectCurrentClubId);

  // These are probably not necessary, logic can be cascated to parent component, most likely //

  const [eventTypes, setEventTypes] = useState([]);

  const [teams, setTeams] = useState([]);

  const [opponents, setOpponents] = useState([]);

  ////////////////////////////////////

  const [columns, setColumns] = useState([]);

  const [clubLocation, setClubLocation] = useState();

  const [selectedRows, setSelectedRows] = useState([]);

  const [canSubmit, setCanSubmit] = useState(false);

  const apiRef = useGridApiRef();

  const eventTypesEndpoint = generateEndpoint(
    apiPathGetEventTypes(currentClubId)
  );

  const teamsEndpoint = generateEndpoint(apiPathClubTeamsList(currentClubId));

  const clubEndpoint = generateEndpoint(apiPathGetClub(currentClubId));

  const opponentsEndpoint = generateEndpoint(
    apiPathGetOpponentList(currentClubId)
  );

  const validateDateFormat = (value) => {
    const date = dayjs(value, "DD/MM/YYYY", true);
    const today = dayjs().startOf("day");
    return date.isValid() && (date.isSame(today) || date.isAfter(today));
  };

  const validateTimeFormat = (value) => {
    return dayjs(value, "HH:mm", true).isValid();
  };

  const validateString = (value, maxLength) => {
    return !isNullOrUndefinedOrEmpty(value) && value?.length <= maxLength;
  };

  const validateStartBeforeEnd = (start, end) => {
    const startTime = dayjs(start, "HH:mm", true);
    const endTime = dayjs(end, "HH:mm", true);
    return startTime.isBefore(endTime);
  };

  const removeSelectedRows = () => {
    const selectedRows = [...apiRef.current.getSelectedRows().values()];
    if (selectedRows?.length === 0) {
      return;
    }
    setRows((prevContent) =>
      prevContent.filter(
        (row) =>
          !selectedRows.some((selectedRow) => selectedRow?.id === row?.id)
      )
    );
    setSelectedRows([]);
  };

  const homeOrAwayMap = {
    home: "home",
    h: "home",
    away: "away",
    a: "away",
    "": "",
  };

  useEffect(() => {
    const getEventTypeOptions = async () => {
      const response = await doGet({ endpoint: eventTypesEndpoint });
      setEventTypes(response?.data ?? []);
      return response?.data ?? [];
    };

    const getTeamOptions = async () => {
      const response = await doGet({ endpoint: teamsEndpoint });
      setTeams(response?.data ?? []);
      return response?.data ?? [];
    };

    const getOpponentOptions = async () => {
      const response = await doGet({ endpoint: opponentsEndpoint });
      setOpponents(response?.data ?? []);
      return response?.data ?? [];
    };

    const getCurrentClub = async () => {
      const response = await doGet({ endpoint: clubEndpoint });
      setClubLocation(response?.data?.location);
      return response?.data?.location;
    };

    const getData = async () => {
      try {
        const [eventTypesData, teamsData, opponentsData, clubLocation] =
          await Promise.all([
            getEventTypeOptions(),
            getTeamOptions(),
            getOpponentOptions(),
            getCurrentClub(),
          ]);

        const mappedEventTypeList = [
          ...map((event) => ({
            value: event?.id,
            label: event?.details?.name,
          }))(eventTypesData),
        ];

        const mappedTeamList = [
          ...map((team) => ({
            value: team?.id,
            label: team?.details?.name,
          }))(teamsData),
        ];

        const mappedOpponentList = [
          ...map((opponent) => ({
            value: opponent?.id,
            label: opponent?.details?.name,
            location: opponent?.location,
          }))(opponentsData),
        ];

        const updatedFileContent = rows.map((item) => {
          const matchedEventTypeOption = mappedEventTypeList.find(
            (event) => event?.label?.trim() === item?.type?.trim()
          );

          const matchedTeamOption = mappedTeamList.find(
            (team) => team?.label?.trim() === item?.team?.trim()
          );

          const matchedOpponentOption = mappedOpponentList.find(
            (opponent) => opponent?.label?.trim() === item?.opponent?.trim()
          );

          const matchedOpponentLocation = matchedOpponentOption?.location;

          return {
            ...item,
            date: dayjs(item?.date, ["D/M/YY", "DD/MM/YYYY", "D/M/YYYY"]),
            start: dayjs(item?.start, "HH:mm"),
            end: dayjs(item?.end, "HH:mm"),
            type:
              item?.type === ""
                ? ""
                : matchedEventTypeOption
                ? matchedEventTypeOption?.value
                : invalidCell,
            team:
              item?.team === ""
                ? ""
                : matchedTeamOption
                ? matchedTeamOption?.value
                : invalidCell,
            opponent:
              item?.opponent === ""
                ? ""
                : matchedOpponentOption
                ? matchedOpponentOption
                : invalidCell,
            homeOrAway:
              homeOrAwayMap[item?.homeOrAway?.trim().toLowerCase()] ||
              invalidCell,
            location:
              homeOrAwayMap[item?.homeOrAway?.trim().toLowerCase()] === "home"
                ? clubLocation
                : matchedOpponentOption &&
                  homeOrAwayMap[item?.homeOrAway?.trim().toLowerCase()] ===
                    "away"
                ? matchedOpponentLocation
                : undefined,
          };
        });

        setRows(updatedFileContent);

        setColumns([
          { field: "id", headerName: "ID", width: 90 },
          {
            field: "event_name",
            headerName: <ColumnHeader columnField={"Event Name"} mandatory={true}/>,
            width: 200,
            editable: true,
            cellClassName: (params) => {
              return !validateString(params?.value, MAX_EVENT_NAME_LENGTH)
                ? "invalid"
                : "";
            },
          },
          // So Interesting To Note: This can not be selected a min date.
          // Might have to do extra validation or custom component
          {
            field: "date",
            headerName: <ColumnHeader columnField={"Date"} mandatory={true}/>,
            width: 110,
            type: "date",
            editable: true,
            valueFormatter: (params) => {
              return dayjs(params).format("DD/MM/YYYY");
            },
            cellClassName: (params) =>
              validateDateFormat(params?.formattedValue) ? "" : "invalid",
          },
          {
            field: "start",
            headerName: <ColumnHeader columnField={"Start"} mandatory={true}/>,
            width: 145,
            editable: true,
            renderEditCell: (params) => <TimePickerEditCell {...params} />,
            valueFormatter: (params) => {
              return dayjs(params).isValid()
                ? dayjs(params).format("HH:mm")
                : "Invalid Time";
            },
            cellClassName: (params) => {
              const isValidFormat = validateTimeFormat(params?.value);
              const endTime = params?.row?.end;
              const isStartBeforeEnd = validateStartBeforeEnd(
                params?.value,
                endTime
              );
              return isValidFormat && isStartBeforeEnd ? "" : "invalid";
            },
          },
          {
            field: "end",
            headerName: <ColumnHeader columnField={"End"} mandatory={true}/>,
            width: 145,
            editable: true,
            renderEditCell: (params) => <TimePickerEditCell {...params} />,
            valueFormatter: (params) => {
              return dayjs(params).isValid()
                ? dayjs(params).format("HH:mm")
                : "Invalid Time";
            },
            cellClassName: (params) => {
              const isValidFormat = validateTimeFormat(params?.value);
              const startTime = params?.row?.start;
              const isStartBeforeEnd = validateStartBeforeEnd(
                startTime,
                params?.value
              );
              return isValidFormat && isStartBeforeEnd ? "" : "invalid";
            },
          },
          {
            field: "type",
            headerName: <ColumnHeader columnField={"Event Type"} />,
            width: 150,
            editable: true,
            type: "singleSelect",
            valueOptions: mappedEventTypeList,
            valueFormatter: (params) => {
              if (params === "" || isNullOrUndefinedOrEmpty(params)) {
                return "";
              }
              const selectedOption = mappedEventTypeList.find(
                (option) => option?.value === params
              );
              return selectedOption ? selectedOption.label : "Invalid Type";
            },
            cellClassName: (params) =>
              params.value !== invalidCell ? "" : "invalid",
          },
          {
            field: "team",
            headerName: <ColumnHeader columnField={"Team"} />,
            width: 130,
            editable: true,
            type: "singleSelect",
            valueOptions: mappedTeamList,
            valueFormatter: (params) => {
              if (params === "" || isNullOrUndefinedOrEmpty(params)) {
                return "";
              }
              const selectedOption = mappedTeamList.find(
                (option) => option?.value === params
              );
              if (params === invalidCell) {
                return "Invalid Team";
              }
              return selectedOption ? selectedOption?.label : "Invalid Team";
            },
            cellClassName: (params) =>
              params?.value !== invalidCell ? "" : "invalid",
          },
          {
            field: "opponent",
            headerName: <ColumnHeader columnField={"Opponent"}/>,
            width: 140,
            editable: true,
            renderEditCell: (params) => (
              <OpponentSelectEditCell
                {...params}
                options={mappedOpponentList}
              />
            ),
            cellClassName: (params) =>
              params?.value !== invalidCell ? "" : "invalid",
            valueFormatter: (params) => {
              if (params === "" || isNullOrUndefinedOrEmpty(params)) {
                return "";
              }
              if (params === invalidCell) {
                return "Invalid Opponent";
              }
              return params?.label;
            },
          },
          {
            field: "homeOrAway",
            headerName: <ColumnHeader columnField={"H/A"} />,
            width: 70,
            editable: true,
            type: "singleSelect",
            valueOptions: [
              {
                value: "home",
                label: "Home",
              },
              { value: "away", label: "Away" },
            ],
            valueFormatter: (params) => {
              if (params === "" || isNullOrUndefinedOrEmpty(params)) {
                return "";
              }
              if (params === invalidCell) {
                return "Invalid Home / Away";
              }
              return params === "home" ? "Home" : "Away";
            },
            cellClassName: (params) =>
              params?.value !== invalidCell ? "" : "invalid",
          },
          {
            field: "location",
            headerName: <ColumnHeader columnField={"Location"} mandatory={true}/>,
            width: 200,
            editable: true,
            renderEditCell: (params) => {
              return <GoogleMapsFieldTest {...params} />;
            },
            valueFormatter: (params) => {
              return params?.description || params?.label;
            },
            cellClassName: (params) =>
              isNullOrUndefinedOrEmpty(params?.formattedValue) ? "invalid" : "",
          },
          {
            field: "locationDescription",
            headerName: <ColumnHeader columnField={"Location Details"} mandatory={true}/>,
            width: 400,
            editable: true,
            cellClassName: (params) =>
              !validateString(params?.value, MAX_DESC_LENGTH) ? "invalid" : "",
          },
          {
            field: "event_description",
            headerName: <ColumnHeader columnField={"Event Details"} mandatory={true}/>,
            width: 400,
            editable: true,
            cellClassName: (params) =>
              !validateString(params?.value, MAX_DESC_LENGTH) ? "invalid" : "",
          },
        ]);
      } catch (error) {
        console.error(error);
      }
    };

    if (currentClubId) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClubId]);

  const isDataValid = (data) => {
    // Must be have at least 1 row and all mandatory fields are filled in
    return (
      data.length > 0 &&
      data.every((item) => {
        const formattedDate = dayjs(item?.date).format("DD/MM/YYYY");
        return (
          validateString(item?.event_name, MAX_EVENT_NAME_LENGTH) &&
          validateDateFormat(formattedDate) &&
          validateTimeFormat(item?.start) &&
          validateTimeFormat(item?.end) &&
          validateStartBeforeEnd(item?.start, item?.end) &&
          // These checks can probably done more dilligently by checking if ID in object but for now should be fine?
          (item?.type !== invalidCell || item?.type === "") &&
          (item?.team === "" || item?.team !== invalidCell) &&
          (item?.opponent === "" || item?.opponent !== invalidCell) &&
          (item?.homeOrAway === "" || item?.homeOrAway !== invalidCell) &&
          !isNullOrUndefinedOrEmpty(item?.location) &&
          validateString(item?.locationDescription, MAX_DESC_LENGTH) &&
          validateString(item?.event_description, MAX_DESC_LENGTH)
        );
      })
    );
  };

  useEffect(() => {
    const isValid = isDataValid(rows);
    setCanSubmit(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  return (
    <CardContainer padding="50px" marginTop="15px" width="100vw">
      <Stack direction={"column"} spacing={4}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"#323E59"} fontWeight={"500"} fontSize={"18px"}>
            Review & Finalise
          </Typography>
          <Stack direction={"row"} spacing={1}>
            <ButtonComponent
              title={
                <Typography fontSize={"12px"} fontWeight={"500"}>
                  Add Row
                </Typography>
              }
              background={darkBlueButtonColor}
              icon={<AddIcon sx={{ fontSize: "16px" }} />}
              onClick={() => {
                const newId = apiRef.current.getRowsCount() + 1;
                const newRow = {
                  id: newId,
                  event_name: "",
                  date: "",
                  start: "",
                  end: "",
                  type: "",
                  team: "",
                  opponent: "",
                  homeOrAway: "",
                  location: "",
                  locationDescription: "",
                  event_description: "",
                };
                setRows((prev) => [...prev, newRow]);
              }}
            />
            <ButtonComponent
              icon={<DeleteOutlineIcon fontSize="small" />}
              background={darkBlueButtonColor}
              title={
                <Typography fontSize={"12px"} fontWeight={"500"}>
                  Delete Selected Row
                </Typography>
              }
              disabled={selectedRows?.length === 0}
              onClick={removeSelectedRows}
            />
          </Stack>
        </Stack>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          sx={{
            "& .MuiDataGrid-cell": {
              border: "1px solid #F1F1F1",
              borderTop: "none",
            },
            "& .MuiDataGrid-row--borderBottom .MuiDataGrid-columnHeader": {
              borderBottom: "none",
            },
            border: "1px solid #F1F1F1",
            "& .invalid": {
              backgroundColor: "rgba(255, 0, 0, 0.1)",
              color: "#ff0000",
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          processRowUpdate={(newRow, oldRow) => {
            // I think there is forsure a better way of doing this too but ?
            if (newRow?.homeOrAway !== oldRow?.homeOrAway) {
              if (newRow?.homeOrAway === "home") {
                newRow.location = clubLocation;
              } else if (newRow?.homeOrAway === "away") {
                if (
                  isNotNullOrUndefinedOrEmpty(newRow?.opponent) &&
                  newRow?.opponent !== invalidCell
                ) {
                  newRow.location = newRow?.opponent?.location;
                }
              }
            }
            const updatedRow = { ...oldRow, ...newRow };

            setRows((prevContent) =>
              prevContent.map((row) =>
                row?.id === updatedRow?.id ? updatedRow : row
              )
            );

            return newRow;
          }}
          apiRef={apiRef}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRows(newRowSelectionModel);
          }}
        />
        <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
          <ButtonComponent
            title={
              <Typography
                fontSize={"12px"}
                fontWeight={"500"}
                paddingX={"30px"}
              >
                Back
              </Typography>
            }
            onClick={() => {
              setPage(0);
              setFileContent(null);
              setHeaders(null);
              setFileName(null);
            }}
          />
          <ButtonComponent
            title={
              <Typography
                fontSize={"12px"}
                fontWeight={"500"}
                paddingX={"40px"}
              >
                Upload Events
              </Typography>
            }
            disabled={!canSubmit || isSnackbarLoading}
            onClick={() => handleBulkUpload(rows)}
          />
        </Stack>
      </Stack>
    </CardContainer>
  );
};

const TimePickerEditCell = ({ id, value, field, api }) => {
  const handleChange = (newValue) => {
    api.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <TimePicker
      value={value ? dayjs(value, "hh:mm A") : null}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} variant="standard" />}
      ampm={false}
      inputFormat="HH:mm"
    />
  );
};

const GoogleMapsFieldTest = ({ id, value, field, api }) => {
  const handleChange = (event) => {
    api.setEditCellValue({
      id,
      field,
      value: event,
    });
  };
  // TODO: Make custom component here as styling is funky but it works for now
  return <GoogleMapsField value={value} setValue={handleChange} id={id} />;
};

const OpponentSelectEditCell = ({ id, value, field, api, options, row }) => {
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    api.setEditCellValue({
      id,
      field,
      value: selectedValue,
    });
    selectedValue?.location &&
      row?.homeOrAway === "away" &&
      api.updateRows([{ id, location: selectedValue?.location }]);
  };

  return (
    <Select value={value} onChange={handleChange} fullWidth variant="standard">
      {options.map((option) => (
        <MenuItem key={option.value} value={option}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const ColumnHeader = ({ columnField, mandatory = false }) => {
  return (
    <Stack 
      direction="row" 
      alignItems="center"
      spacing={0.5}
    >
      <Typography>{columnField}</Typography>
      {mandatory && (
        <Typography color="error" component="span" sx={{ fontSize: "inherit" }}>
          *
        </Typography>
      )}
    </Stack>
  );
};

export default ReviewPage;
