import { useState, useRef } from "react";
import BasePageComponent from "../../components/base-page-component";
import { blackTextColor } from "../../styles/style-constants";
import { eventsRoute } from "../../utils/page-routes";
import { Stack, Typography, Box } from "@mui/material";
import { LinearProgress, linearProgressClasses } from "@mui/material";
import { blueButtonColor } from "../../styles/style-constants";
import { length, map, addIndex } from "ramda";
import InitalUploadPage from "./inital-upload-page";
import Papa from "papaparse";
import ReviewPage from "./data-grid-review";
import {
  combineDateAndTime,
  formatDateTimeForBackend,
} from "../../utils/helper-functions/datetime-functions";
import dayjs from "dayjs";
import CompletedUpload from "./final-page";
import { useApiUtilsContext } from "../../providers/api-utils-provider";
import { apiPathBulkUpload } from "../../utils/endpoint-paths";
import { useSelector } from "react-redux";
import { selectCurrentClubId } from "../../redux/selectors";
import { templateHeaders } from "../../utils/constants";

const ImportPage = () => {
  const [fileContent, setFileContent] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [fileName, setFileName] = useState();
  const fileInputRef = useRef();
  const [isSnackbarLoading, setSnackbarLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [isFileValid, setIsFileValid] = useState(true);
  const [rows, setRows] = useState([]);
  const currentClubId = useSelector(selectCurrentClubId);
  const { doGet, generateEndpoint, doPost } = useApiUtilsContext();

  const bulkUploadEndpoint = generateEndpoint(apiPathBulkUpload(currentClubId));

  const validateFileTemplate = (headers) => {
    return templateHeaders.every((header, index) => header === headers[index]);
  };

  const handleFileRead = (result) => {
    Papa.parse(result, {
      header: true,
      skipEmptyLines: true,
      complete: (parsedData) => {
        setHeaders(parsedData?.meta?.fields);
        setFileContent(parsedData?.data);
        setIsFileValid(validateFileTemplate(parsedData?.meta?.fields));
      },
      error: (error) => {
        console.error("Error parsing CSV: ", error);
      },
    });
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e?.dataTransfer?.files;
    if (files?.length > 0) {
      const file = files[0];
      if (file?.name.endsWith(".csv")) {
        setFileName(file?.name);
        readFile(file);
      }
    }
  };

  const handleFileChange = (e) => {
    const files = e?.target?.files;
    const file = files[0];
    if (file?.name.endsWith(".csv")) {
      // There is probably a better way of doing this
      setFileName(file?.name);
      readFile(file);
    }
  };

  const handleSelectFile = () => {
    fileInputRef.current.click();
  };

  const readFile = (file) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      handleFileRead(e?.target?.result);
    };
    fileReader.readAsText(file);
  };

  const removeFileHandler = () => {
    setHeaders(null);
    setFileContent(null);
    setFileName(null);
    setIsFileValid(true);
  };

  const handleUploadToFinalise = () => {
    const mapIndexed = addIndex(map);
    if (fileContent) {
      setRows(
        mapIndexed((data, idx) => {
          return {
            id: idx + 1,
            ...data,
          };
        }, fileContent)
      );
    }
    setPage((prev) => prev + 1);
  };

  const handleEventsDownloadTemplate = () => {
    const data = [templateHeaders];
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "template.csv");
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleSkipUpload = () => {
    setFileContent([]);
    handleUploadToFinalise();
  };

  const handleBulkUpload = async (events) => {
    const mapDataBody = (data) => {
      return {
        name: data?.event_name,
        start: formatDateTimeForBackend(
          combineDateAndTime(dayjs(data?.date), data?.start)
        ),
        end: formatDateTimeForBackend(
          combineDateAndTime(dayjs(data?.date), data?.end)
        ),
        ...(data?.type && { eventTypeId: data?.type }),
        ...(data?.team && { teamId: data?.team }),
        ...(data?.opponent && { opponentId: data?.opponent?.value }),
        ...(data?.homeOrAway && { homeOrAway: data?.homeOrAway }),
        description: data?.event_description,
        eventLocation: {
          locationDetails: data?.locationDescription,
          location: data?.location,
        },
      };
    };

    const body = map(mapDataBody)(events);

    try {
      setSnackbarLoading(true);
      await doPost({ endpoint: bulkUploadEndpoint, body: body });
      setSnackbarLoading(false);
      setPage((prev) => prev + 1);
    } catch (error) {
      setSnackbarLoading(false);
      console.error(error);
    }
  };

  const pages = [
    <InitalUploadPage
      handleFileRead={handleFileRead}
      handleDrop={handleDrop}
      handleFileChange={handleFileChange}
      handleSelectFile={handleSelectFile}
      removeFileHandler={removeFileHandler}
      fileContent={fileContent}
      fileInputRef={fileInputRef}
      fileName={fileName}
      handleUploadToFinalise={handleUploadToFinalise}
      handleEventsDownloadTemplate={handleEventsDownloadTemplate}
      isFileValid={isFileValid}
      handleSkipUpload={handleSkipUpload}
    />,
    <ReviewPage
      fileContent={fileContent}
      setFileContent={setFileContent}
      setPage={setPage}
      setFileName={setFileName}
      setHeaders={setHeaders}
      handleBulkUpload={handleBulkUpload}
      setSnackbarLoading={setSnackbarLoading}
      isSnackbarLoading={isSnackbarLoading}
      rows={rows}
      setRows={setRows}
      doGet={doGet}
      generateEndpoint={generateEndpoint}
    />,
    <CompletedUpload uploadedEventsCount={rows?.length} setPage={setPage} removeFileHandler={removeFileHandler}/>,
  ];

  return (
    <BasePageComponent
      backRoute={eventsRoute}
      snackbarLoading={isSnackbarLoading}
      snackbarLoadingMessage={"Loading..."}
      snackbarDoneMessage={"Successfully Imported!"}
      pageTitle={
        <Typography
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          fontWeight={500}
          fontSize={"20px"}
          lineHeight={"29px"}
          color={blackTextColor}
          ml={1}
        >
          Import CSV
        </Typography>
      }
    >
      <Stack width={"min-content"} direction="column">
        <Box marginLeft={"50px"} marginTop="10px">
          <Typography
            fontSize={"14px"}
            color={blueButtonColor}
            width={"100%"}
            textAlign={"right"}
          >
            Page {page + 1} of {length(pages)}
          </Typography>
          <LinearProgress
            sx={{
              background: "#B3DAFA",
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor: blueButtonColor,
              },
            }}
            value={Math.ceil(((page + 1) / length(pages)) * 100)}
            variant="determinate"
          />
        </Box>
        {pages[page]}
      </Stack>
    </BasePageComponent>
  );
};

export default ImportPage;
