import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material"
import FieldLabel from "../../components/fields/field-label"
import { parseBackendDatetime, timeFromDateTime } from "../../utils/helper-functions/datetime-functions"
import dayjs from "dayjs"
import { blueButtonColor, redButtonColor } from "../../styles/style-constants"
import { Check, Close, MoreHoriz, Person, SwapHorizontalCircle } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { editEventRoute } from "../../utils/page-routes"
import ButtonComponent from "../../components/button-component"
import SearchAutocompleteField from "../../components/fields/search-autocomplete-field"
import { useSelector } from "react-redux"
import { selectCurrentClubId  } from "../../redux/selectors"
import { useApiUtilsContext } from "../../providers/api-utils-provider"
import { map } from "ramda"
import { apiPathClubMembers, apiPathDelegateAcitivty } from "../../utils/endpoint-paths"
import useDoNavigate from "../../utils/do-navigate"



const getNotificationTime = (notification, longForm = false) => {
    const dateTime = parseBackendDatetime(notification?.created)
    const today = dayjs()
    const difference = today.diff(dateTime, "day")
    const isToday = dayjs().isSame(dateTime, "day")
    const formattedTime = dateTime.format("h:mm A")
    if (isToday) {
        return formattedTime
    } else if (difference <= 1) {
        return `Yesterday${longForm ? `, at ${formattedTime}` : ""}`
    } else if (difference <= 6 && !longForm) {
        return `${difference} days ago`
    } else if (today.isSame(dateTime, "year")) {
        let formatString = dateTime.format("dddd, MMMM D")
        let suffix = "th"
        if (formatString.endsWith(" 1")) {
            suffix = "st"
        } else if (formatString.endsWith(" 2")) {
            suffix = "nd"
        } else if (formatString.endsWith(" 3")) {
            suffix = "rd"
        }
        if (longForm) {
            suffix = `${suffix}, at ${formattedTime}`
        }
        return `${formatString}${suffix}`
    } else {
        return dateTime.format("DD MMM, YYYY")
    }
}


export const NotificationListItemBase = ({
    notification,
    getBodyText = () => "body",
    getBody = () => <></>,
    markAsUnread = () => { },
    markAsRead = () => { },
    image = <Person fontSize="large" />,
    setSelectedNotification = () => { },
    selected = false
}) => {

    const read = notification?.read

    const [anchorEl, setAnchorEl] = useState(null)

    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event?.currentTarget)
    }

    const onClose = () => {
        setAnchorEl(null)
    }

    const onClickMenu = () => {
        if (read) {
            markAsUnread(notification?.id)
        } else {
            markAsRead(notification?.id)
        }
        onClose()
    }



    return <Stack direction="column">
        <Grid
            container
            paddingY="5px"
            justifyContent={"space-between"}
            sx={{
                ":hover": {
                    bgcolor: !selected && "#EFEFEF"
                },
                cursor: !selected && "pointer",
                bgcolor: selected && "#EFEFEF"
            }}
            onClick={() => {
                setSelectedNotification(notification?.id)
            }}
        >
            <Grid item xs={2}>
                <Stack justifyContent={"center"} alignItems={"center"}>
                    {image}
                </Stack>

            </Grid>
            <Grid item xs={8}>
                <Stack direction="column" spacing={1} marginBottom="5px">
                    <FieldLabel label={notification?.title} color={read ? "#979797" : blueButtonColor} />

                    <Typography fontSize="14px">{getBodyText(notification)}</Typography>
                    {getBody(notification)}
                    <Typography
                        fontSize="12px"
                        fontWeight={700}
                        color={read ? "#979797" : blueButtonColor}
                    >
                        {getNotificationTime(notification)}
                    </Typography>


                </Stack>
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={handleClick} sx={{ top: "-11px" }}>
                    <MoreHoriz />
                </IconButton>
                <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
                    <MenuItem onClick={onClickMenu}>
                        <Check fontSize="small" /> {read ? "Mark as Unread" : "Mark As Read"}
                    </MenuItem>
                </Menu>

            </Grid>
        </Grid>
        <Divider />
    </Stack>
}

export const NotificationPageBase = ({
    notification,
    image,
    is_admin = false,
    getNotificationTitle = () => "title",
    getBodyOpener = () => "",
    getBodyText = () => "",
    getBody = () => <></>,
    getActions = () => <></>,
    getFooter = () => <></>,
    getActionsText = () => <></>
}) => {
    
    return <Box position={"relative"} right="-10px">
        <Box bgcolor={"white"} padding="10px" borderRadius={"10px"} position="absolute" left="0px" height="100%">
            <Stack direction="row" minWidth={"60ch"} spacing={2} alignItems={"center"} marginBottom={"5px"}>
                {image}
                <Stack>
                    <Typography fontSize="24px" textTransform={"uppercase"} fontWeight={"500"}>
                        {notification?.title}
                    </Typography>
                    <Typography
                        fontSize="12px"
                        fontWeight={700}
                        color={"#979797"}
                    >
                        {getNotificationTime(notification, true)}
                    </Typography>
                </Stack>

            </Stack>

            <Divider />

            <Stack marginTop="10px" marginX="20px">
                <Typography>
                    {getBodyOpener()}
                </Typography>
                <Typography>
                    {getBodyText()}
                </Typography>
                <Box marginTop="10px" marginBottom="20px" paddingX="10px">
                    {getBody()}
                </Box>
                <Box marginBottom="10px">
                    {getActionsText()}
                </Box>
                <Box marginBottom="10px">
                    {getActions()}
                </Box>
                {getFooter()}
            </Stack>




        </Box>
    </Box>

}

export const NotificationEventDetailsList = ({ event, showEventName = true, showTime = false, showLocation, activity, showActivity }) => {

    const doNavigate = useDoNavigate()


    return <Stack spacing={1}>
        {showEventName &&
            <Stack>
                <Typography fontSize={"14px"} fontWeight={700}>
                    Event Name:
                </Typography>
                <Typography noWrap width={"min-content"} sx={{ textDecoration: "underline", cursor: "pointer" }} color={blueButtonColor} onClick={() => {
                   doNavigate(editEventRoute, {target:event.id})
                }}>
                    {event?.details?.name ?? "..."}
                </Typography>
            </Stack>
        }

        {showTime &&
            <Stack>
                <Typography fontSize={"14px"} fontWeight={700}>
                    Time:
                </Typography>
                <Typography fontSize={"14px"}>
                    {timeFromDateTime(parseBackendDatetime(event?.start)).format("h:mm A")}
                </Typography>
            </Stack>
        }
        {showLocation &&
            <Stack>
                <Typography fontSize={"14px"} fontWeight={700}>
                    Location:
                </Typography>
                <span>
                    {event?.homeOrAway?.length > 0 && <span style={{ textTransform: "capitalize" }}>({event?.homeOrAway}) </span>}
                    <a
                        href={`https://www.google.com/maps/place/?q=place_id:${event?.eventLocation?.location?.place_id}`}
                        target="blank"
                    >
                        {event?.eventLocation?.location?.description}
                    </a>
                    {event?.eventLocation?.locationDetails && <span>
                        , {event?.eventLocation?.locationDetails}
                    </span>}
                </span>

            </Stack>
        }
        {showActivity &&
            <Stack>
                <Typography fontSize={"14px"} fontWeight={700}>
                    Volunteering Activity:
                </Typography>
                <Typography fontSize={"14px"}>
                    {activity?.details?.name ?? "..."}
                </Typography>
            </Stack>
        }

    </Stack>


}


export const NotificationDelegateDialog = ({ open, setOpen, onDelegate = () => {}, name, event, activity, bodyText, updateStatus, groupedOptions = null, getOptionDisabled = null, getOptionLabel = null, groupBy = null, renderOption = null }) => {

    const currentClubId = useSelector(selectCurrentClubId)
    const { doGet, generateEndpoint, doPut } = useApiUtilsContext()

    const getClubMembersEndpoint = generateEndpoint(apiPathClubMembers(currentClubId))
    const delegateEndpoint = (delegateeId) => generateEndpoint(apiPathDelegateAcitivty(activity?.id, delegateeId))

    const [memberList, setMemberList] = useState([])
    const [value, setValue] = useState()

    const [isLoading, setIsLoading] = useState(false);



    const mapMembers = (member) => {
        return {
            label: member?.fullName || member?.email,
            id: member?.id
        }
    }


    const mappedMemberList = map(mapMembers)(memberList)


    useEffect(() => {

        const getClubMembers = async () => {
            const response = await doGet({ endpoint: getClubMembersEndpoint })
            setMemberList(map((member) => member?.member)(response?.data))
        }

        if (currentClubId) {
            getClubMembers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentClubId])

    const onHandleDelegate = async () => {

        const personId = value?.id
        setIsLoading(true);
        try {
            await doPut({endpoint: delegateEndpoint(personId)})
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false);
        onClose()
        !updateStatus ? onDelegate() : onDelegate(value, activity, memberList)
    }

    const onClose = () => {
        setValue()
        setOpen(false)
    }

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>Delegate Activity</DialogTitle>
        <DialogContent>
            <Stack marginTop="10px" marginX="20px">
                <Typography>
                    Hey {name},
                </Typography>
                <Typography>
                    {bodyText}
                </Typography>
                <Box marginTop="10px" marginBottom="20px" paddingX="10px">
                    <NotificationEventDetailsList
                        event={event}
                        showLocation
                        showTime
                        activity={activity}
                        showActivity
                    />
                </Box>
                <Typography marginBottom={"10px"}>
                    Please elect a replacement to fulfil this Volunteer Activity:
                </Typography>
                <Stack spacing={2} width={"min-content"}>
                    <SearchAutocompleteField
                            options={groupedOptions ? groupedOptions(activity) : mappedMemberList}
                            value={value}
                            setValue={setValue}
                            groupBy={groupBy}
                            getOptionDisabled={getOptionDisabled}
                            getOptionLabel={getOptionLabel}
                            renderOption={renderOption}
                        />
                </Stack>
            </Stack>
        </DialogContent>
        <DialogActions>
            <ButtonComponent
                title="Cancel"
                onClick={onClose}
                background={redButtonColor}
                icon={<Close/>}
                disabledBackground={"#FFA3A6"}
                disabled={isLoading}
            />
            <ButtonComponent
                title="Delegate"
                onClick={onHandleDelegate}
                background={blueButtonColor}
                icon={<SwapHorizontalCircle />}
                disabled={isLoading}
                disabledBackground={"#7fcae6"}
            />
        </DialogActions>
    </Dialog>
}