import { Stack, Input } from "@mui/material";
import FieldLabel from "../field-label";
import { fieldWidth } from "../../../styles/style-constants";
import ReadOnlyField from "../readonly-field";

const FormField = ({ value, setValue, id, placeholder, label, inputProps, spacing = 2, width, readOnly = false, mandatory=false }) => {
    return (
        <Stack direction="column" spacing={label && spacing} maxWidth={width ?? fieldWidth}>
            <FieldLabel label={label} mandatory={mandatory} readOnly={readOnly}/>
            
            {readOnly ? <ReadOnlyField value={value ?? ""}/> : 
            <Input
                id={id}
                placeholder={placeholder}
                readOnly={readOnly}
                value={value ?? ""}
                inputProps={inputProps}
                onChange={(e) => setValue(e.target.value)}
            />}
        </Stack>
    );
};

export default FormField;